<template>
  <video
    :poster="fileWithCORSHeaders(blok.poster?.filename) + '/m/'"
    class="media-player"
    preload="metadata"
    controls
    width="100%"
    height="100%"
  >
    <source :src="blok.asset?.filename" />
    <track
      v-for="webvtt in blok.subtitles"
      :key="webvtt._uid"
      :label="webvtt.label"
      :srclang="webvtt.language"
      :src="fileWithCORSHeaders(webvtt.filename)"
      kind="captions"
    />
  </video>
</template>

<script setup>
defineProps({
  blok: {
    type: Object,
    required: true,
  },
});

const fileWithCORSHeaders = (filename) => {
  return filename.replace("//a.storyblok.com", "//a2.storyblok.com");
};
</script>

<style lang="scss" scoped>
.media-player {
  border-radius: $radius-l;
  width: 100%;
}
</style>
